import dynamic from 'next/dynamic'
import type { FC } from 'react'

import useMediaQuery from '@hooks/mediaQueries/useMediaQuery'
import { useLogOut } from '@hooks/user/useLogOut/useLogOut'
import { mediaQueries } from '@themes/mediaQueries'

const LogoutButton = dynamic(() => import('./components/LogoutButton'))

const Logout: FC = () => {
  const onlyDesktop = useMediaQuery(mediaQueries.onlyDesktop)
  const { isLoggedIn, logOutHandler } = useLogOut()

  if (!isLoggedIn || !onlyDesktop) return

  return <LogoutButton logOutHandler={logOutHandler} />
}

export default Logout
